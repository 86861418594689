.trans-hide {
    animation: fadeOut 1s forwards;
    animation-delay: 3s;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

