.centris-c-progress {
    height: 1rem;

    @apply inline-block;
}

.centris-c-progress[value] {
    @apply appearance-none border-none text-omni-green;
}

.centris-c-progress[value]::-webkit-progress-bar {
    @apply rounded bg-gray-200
}

.centris-c-progress[value]::-webkit-progress-value {
    @apply rounded-l bg-omni-green;
}

.centris-c-progress[value="100"]::-webkit-progress-value {
    @apply rounded-r
}
