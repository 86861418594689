.centris-c-fileInput {
    height: 2.5rem;

    @apply relative inline-block cursor-pointer;
}

.centris-c-fileInput--input {
    min-width: 14rem;
    filter: alpha(opacity=0);

    @apply opacity-0
}

.centris-c-fileInput--prompt {
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @apply px-4 py-2 text-base absolute border rounded border-gray-300 bg-white text-gray-500
}

.centris-c-fileInput--prompt:after {
    content: "Choose files...";
}

.centris-c-fileInput--prompt:before {
    top: -.075rem;
    right: -.075rem;
    bottom: -.075rem;
    z-index: 6;
    display: block;
    content: "Browse";
    height: 2.5rem;
    line-height: 1.5;

    @apply px-4 py-2 text-base absolute border rounded-l-none rounded-r border-omni-blue bg-gray-400 text-white bg-omni-blue
}

.centris-c-fileInput--prompt:hover:before {
    @apply bg-omni-blue-light
}

.centris-c-fileInput--prompt:focus:before {
    @apply outline-none shadow-outline
}
